<template>
  <!-- 申请志愿者 -->
  <div class="applyVolunteer" v-if="!isNext">
    <div class="main">
      <div class="item">
        <div class="item-l">姓名</div>
        <div class="item-r">
          <v-input
            inputAlign="right"
            v-model="form.name"
            :maxlength="10"
            placeholder="请输入姓名"
          ></v-input>
        </div>
      </div>
      <div class="item">
        <div class="item-l">手机号</div>
        <div class="item-r">
          <v-input
            disabled
            type="number"
            inputAlign="right"
            :maxlength="11"
            v-model="form.phone"
            placeholder="请填写手机号"
          />
        </div>
      </div>
      <div class="item br textarea">
        <div class="item-l">申请理由</div>
        <div class="item-r">
          <v-input
            v-model="form.pursueReason"
            placeholder="请填写申请理由"
            type="textarea"
            :maxlength="30"
            :showWordLimit="true"
          ></v-input>
        </div>
      </div>
    </div>
    <!-- <div class="submit" @click="submitBefore">提交申请</div> -->
    <div class="submit" @click="submitBefore">下一步</div>
  </div>
  <undertaking :isFromMini="isFromMini" :params="form" v-else></undertaking>
</template>

<script>
import { appliyVolunteerUrl, userInfoUrl } from "./api";
import wx from "weixin-js-sdk";
import undertaking from "./undertaking.vue";

export default {
  components: {
    undertaking,
  },
  data() {
    return {
      isNext: false,
      userInfo: {},
      isFromMini: "",
      form: {
        name: "", // 志愿者姓名
        phone: "", // 志愿者电话
        pursueReason: "", // 申请原因
      },
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
  },
  created() {
    this.isFromMini = this.$route.query.isFromMini;
    this.getUserInfo();
  },
  methods: {
    async submitBefore() {
      if (!this.form.name) {
        this.$toast("请填写姓名");
        return;
      }
      if (!this.form.phone) {
        this.$toast("请填写手机号");
        return;
      }
      if (this.form.phone.length != 11) {
        this.$toast("手机号格式错误");
        return;
      }
      if (!this.form.pursueReason) {
        this.$toast("请填写申请理由");
        return;
      }
      this.isNext = true;
      // this.submit();
    },

    submit() {
      let params = {
        userId: this.userId,
        ...this.form,
      };
      this.$axios.post(appliyVolunteerUrl, params).then((res) => {
        if (res.code === 200) {
          this.$toast("申请成功");
          if (this.isFromMini == 1) {
            wx.miniProgram.navigateBack({
              delta: 1,
            });
          } else {
            this.$router.go(-1);
          }
        }
      });
    },
    // 获取用户信息
    getUserInfo() {
      let params = {
        userId: this.userId,
      };
      this.$axios
        .get(userInfoUrl, {
          params,
        })
        .then((res) => {
          if (res.code === 200) {
            this.userInfo = res.data;
            this.form.phone = res.data.mobile;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.applyVolunteer {
  min-height: 100%;
  background: #fafafaff;
  box-sizing: border-box;
  padding: 24px 32px 0;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  .main {
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
    border-radius: 16px;
    padding: 30px 24px 68px 24px;
    .item {
      display: flex;
      .item-l {
        line-height: 88px;
        color: #333333;
        font-size: 32px;
        width: 150px;
        text-align: left;
      }
      .item-r {
        flex: 1;
      }
      &.br {
        display: block;
      }
      &.textarea {
        .item-r {
          ::v-deep .van-cell {
            background: #0000000a;
          }
        }
      }
    }
  }
  .submit {
    position: fixed;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    width: 550px;
    height: 66px;
    font-size: 28px;
    line-height: 66px;
    text-align: center;
    font-weight: bold;
    color: #ffffff;
    background: linear-gradient(180deg, #72a8ff 0%, #3680ff 100%);
    border-radius: 10px;
    margin-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
    margin-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  }
}
</style>
